<template>
  <div class="forecast_page">
    <div id="show">
      <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true }"></v-header>
      </div>
      <div class="title" id="detail_title">
        <div class="iconinfo" @click="gotohistory">
          <img :src="masterImg"/>
          <div class="pp">
            <p class="name">{{masterName}}</p>
            <p class="history">查看历史记录</p>
          </div>
          
        </div>

        <h5 style="margin:0;">{{item.title}}</h5>
        <p><span>{{item.type==5?"排列五":"七星彩"}}</span>{{item.date}}</p>
      </div>
      <div class="content" v-if="!headImg">
        <p>本文隐藏内容需打赏</p>
        <p>注：打赏纯属自愿行为</p>
        <p>如有疑问添加客服微信：haojingchou6</p>
        <p class="tip">
          声明：本平台所发布的任何预测信息都仅作为娱乐参考，不可作为购彩依据，如因此购彩产生任何纠纷均与平台无关！
        </p>
        <p class="price">
          {{item.price!='0'?'月亮：'+(item.price/100):'免费'}}
        </p>
        <button @click="confirm">立即打赏</button>
      </div>
      <div v-if="headImg" class="headImg">
        <img :src="headImg" @click="preview($event)" alt="" ref="headImg">
        <!-- <img :src="headImg" @click="preview($event)" alt="" ref="headImg"> -->
      </div>
      <!-- <div style="text-align: center">
        <a class="complain" @click="complainShow">投诉</a>
      </div> -->
      <van-dialog title="投诉" v-model="dialogShow"
                  :show-confirm-button="true"
                  :show-cancel-button="true"
                  :confirm-button-text="'提交'"
                  :before-close="complainBeforeClose">
        <van-field label="投诉内容"
                   v-model="complainMes"
                   placeholder="请输入投诉内容"
                   colon
                   label-width="70px"
                   required
                   autosize
                   maxlength="100"
                   show-word-limit
                   type="textarea"></van-field>

        <van-field label="联系方式"
                   maxlength="25"
                   label-width="70px"
                   v-model="contactMes"
                   colon
                   required
                   placeholder="请输入联系方式(手机号或邮箱)"></van-field>

      </van-dialog>
    </div>
    <div style="text-align: center;display: none;background-color: white;height: 790px;" id="hid">
      <p style="margin: 0px;padding-top: 10px;">
        <img src="../../assets/img/pay/tip1.png" width="200px" style="position: relative;left: 80px;">
        <br>
        <img src="../../assets/img/pay/tip2.png" width="200px">
      </p>
      <span style="font-size: 20px;">请在浏览器中打开进行支付或复制链接<br>到浏览器打开,或点击关闭到上一页</span>
      <br>
      <span style="font-size: 20px;" id="url">链接</span>
      <br>
      <van-button plain type="success" @click="returnBack()" style="font-size: 1px;width: 110px;height: 50px;margin: 0;padding: 0;border-radius: 10px;font-size: 20px">关闭</van-button>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'
import preview from '@/utils/preview.js'
import {ImagePreview} from 'vant'
export default {
  components: {
    vHeader
  },
  data() {
    return {

      title: this.$route.meta.title,
      item:{},
      info:{},
      showHeader:true,
      headImg:'',
      masterImg:'',
      masterName:'',
      dialogShow: false,
      complainMes: '',
      contactMes: '',
      artId:this.$route.query.artId,
      userId: this.$store.state.common.loginInfo.userId,
    }
  },
  computed:{
    // masterName(){
    //   return this.item.title.substr(1,3)
    // }
  },
  methods: {
    async getArticleDetail(){
      let data = {
        art_id: this.artId,
      }
      const res = await this.$HTTP.get(this, this.$API['API_ARTICLE_DETAILS'],data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
      if(res.statusCode == 200 || res.statusCode == 309){
        this.info=res.data;
        this.headImg = res.data.conImage;
        this.masterImg = res.data.headImgUrl;
        this.item.title = res.data.artTitle;
        this.item.price = res.data.price;
        this.item.type = res.data.lotteryKind;
        let product_date = new Date(res.data.artDate);
        this.item.date = product_date.getFullYear()+'-'+(product_date.getMonth() + 1) + '-' + product_date.getDate();
        setTimeout(() => {
          if (this.$refs.headImg != null && this.$refs.headImg !='')
          {
            let img = this.$refs.headImg
            let src = img.src;
            let newImg = `<img src="${src}">`;
            [this.html, this.richImages] = preview.addRandomAndGetPreviewImageUrlsFromRichText(newImg)
          }
        }, 200)
      }
    },
    async getproduct(){
      // console.log(this,info.productId)
      let data = {
        product_id: this.info.productId,
      }
            const res = await this.$HTTP.get(this, this.$API['API_PRODUCT_DETAILS'],data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                                if(res.statusCode == 200){
                                    // console.log('产品详细',res)
                                    this.masterName = res.data.productName


                                }
    },
    gotohistory(){
      this.$router.push({
          name:'master_content_history',
          query:{
            productId:this.info.productId,
            masterImg:this.masterImg,
            masterName:this.masterName,
            lotteryKind:this.item.type,

          }
      })
    },
    complainShow(){
      this.dialogShow = true
    },
    
    async complainBeforeClose(action,done){
      if (action === "confirm")
      {
        if (this.complainMes == '' || this.complainMes == null)
        {
          this.$toast("投诉内容不能为空");
          return done(false)
        }
        else if (this.contactMes == '' || this.contactMes == null)
        {
          this.$toast("联系方式不能为空");
          return done(false)
        }
        else
        {
          let complainMessage = {
            complainArtId: this.artId,
            complainUserId: this.$store.state.common.loginInfo.userId,
            complainText: this.complainMes,
            contact: this.contactMes
          }
          const res = await this.$HTTP.post(this,'API_COMPLAIN_TEXT',complainMessage,{ headers: { access_token: this.$store.state.common.loginInfo.access_token }},true);
          if (res.responseDescription ==='成功')
          {
            this.$toast("提交成功");
          }else {
            this.$toast(res.responseDescription);
            this.contactMes = '';
            this.complainMes = '';
            return done();
          }
          this.contactMes = '';
          this.complainMes = '';
          return done()
        }
      }
      else
      {
        this.dialogShow = false;
        this.contactMes = '';
        this.complainMes = '';
        done()
      }
    },
    returnBack(){
      var boxhide = document.getElementById("show")
      var boxshow = document.getElementById("hid")
      boxhide.style.display = "block"
      boxshow.style.display = "none"
    },
    async onLoad() {

    },

    async pay(){
      // let isWeiXin = !this.showHeader
      let isWeiXin = isWeixin()
      var boxshow1 = document.getElementById("hid")
      boxshow1.style.display = "none"
      if (isWeiXin)
      {
        var boxhide = document.getElementById("show")
        var boxshow = document.getElementById("hid")
        boxhide.style.display = "none"
        boxshow.style.display = "block"
        var url = window.location.origin
        var alterUrl = document.getElementById("url")
        alterUrl.innerHTML = "好劲抽官网 "+url
        return;
      }
      if(this.$store.state.common.loginInfo.access_token == null || this.$store.state.common.loginInfo.access_token == ''){
        console.log(this.$store.state.common.loginInfo.access_token);

        this.$toast('请先登录')
        let to_login = window.location.origin + '/#/login?return_url=' + window.location.href.split('?')[0] + '?artId=' + this.artId;
        window.location.href = to_login;
      }else{
          let data = {
                
                art_id: this.artId,
                user_id: this.userId
                
          }
          const res = await this.$HTTP.get(this, this.$API['API_PAY_POINT'], data, { headers: { access_token : this.$store.state.common.loginInfo.access_token } },true)
            if(res && res.statusCode === 200){
              this.$toast.clear()
              // console.log('支付成功后信息：',res)
              this.getArticleDetail()
              // let newUrl = res.prepayUrl;
              // /*let flags = "redirect_url=";
              // let lastIndex = res.prepayUrl.indexOf(flags);
              // if (lastIndex !== -1) {
              //   newUrl = res.prepayUrl.substring(0, lastIndex+flags.length) + window.location.href;
              //   window.location.href = newUrl+'?timestamp='+((new Date()).getTime()+Math.random());
              // }*/
              // window.location.href = newUrl;
            } else {
              console.log('支付失败',res.responseDescription);
            }

        }
      },
    
      preview(e){
        if (e.target.tagName == 'IMG') {
          ImagePreview({
            images: this.richImages,
            //  e.target.src.split('random=')[1]
            startPosition: 0,
            closeOnPopstate: true, //页面回退关闭预览
            showIndex:true,
            closeable: true,
          })
        }
      },
      confirm(){
        this.$dialog({
          title: '确认支付',
          message:
            '打赏纯属自愿行为,任何预测信息都仅作为娱乐参考，不可作为购彩依据，如因此购彩产生任何纠纷均与平台无关！',
            showCancelButton: true,
        })
          .then(() => {
            this.pay()
            // on confirm
            // console.log('点击了确认')
          })
          .catch(() => {
            // on cancel
            // console.log('点击了取消')

          });
      },
  },
  filters: {
    setDate(vle) {
      if (vle) { return `${vle.substr(5, 2)}月${vle.substr(8, 2)} ${vle.substr(11, 5)}` }
      return '';
    }
  },
  created() {
    // console.log('artid',this.artId)
  },
  async mounted(){
    // console.log(this.artId)
    //query的Id为登录跳转返回 params的Id为点击打开详情中的参数id
    if (this.$route.query.artId !=null)
    {
      this.artId = this.$route.query.artId;
    }
    if (this.$route.params.artId !=null)
    {
      this.artId = this.$route.params.artId
    }
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    if(this.$store.state.common.loginInfo.access_token == null || this.$store.state.common.loginInfo.access_token === ''){
      console.log(this.$store.state.common.loginInfo.access_token);
      this.$toast('请先登录')
      let to_login = window.location.href.split('?')[0] + '?artId=' + this.artId;
      this.$router.push({name:'login',params:{return_url:to_login}})
    }else {
      await this.getArticleDetail()
       this.getproduct()

        
      }
  }

}


</script>

<style lang="less">
.forecast_page {
  .title{
    .iconinfo{
      display: inline-block;
      img{
        // display: inline-block;
        float: left;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin: 0.2rem 0;
      }
      .pp{
        // display: block;
        // display: inline-block;
        float: left;
        // padding: 0.3rem 0;
        margin: 0.1rem 0.3rem;
      }
      .name{
        display: block;
        font-size: 0.5rem;
        margin: 0;
        padding: 0;
      }
      .history{
        display: block;
        font-size: 0.3rem;
        opacity: 0.5;
        margin: 0;
        text-decoration: underline;
      }
    }
    text-align: center;
    h5{
      font-size: 18px;
    }
    p{
      font-size: 12px;
      span{
        color: #3abaf7;
        display: inline-block;
        margin-right: 4px;
      }
    }
  }
  .content{
    width: 92%;
    height: 16%;
    background-color: #eee;
    margin: 0 auto;
    border: 1px dashed #ccc;
    text-align: center;
    padding: 6px 0;
    p{
      font-size: 12px;
      padding: 0;
      margin: 0;
      line-height: 16px;
    }
    .tip{
      font-size: 8px;
      color: #f6733b;
    }
    .price{
      color: red;
    }
    button{
      border:none;
      background-color: #ebad44;
      font-size: 10px;
      color: #fff;
      border-radius: 8px;
      padding: 2px 8px;
    }
  }
  .van-tabs__line {
    background-color: #3abaf7;
  }
  .list_box {
    background-color: white;
    .list {
      display: flex;
      padding: 4% 0;
      margin: 0 4%;
      .photo {
        border-radius: 2px;
        overflow: hidden;
      }
      .cnt {
        width: 0;
        flex: 1;
        margin-left: 8px;
        .name {
          font-size: 16px;
          margin: 0;
        }
        .txt {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #999;
          .price {
            color: #ff976a;
          }
        }
      }
    }
  }
  .headImg{
    width: 92%;
    margin: 0 auto;
    margin-top: 10px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .complain{
    margin: auto;
    text-decoration: none;
    font-size: 15px;
    color: blue;
  }
}
</style>
