const addRandomAndGetPreviewImageUrlsFromRichText = html => {
    // 如果没有值的话，直接返回
    if (!html) {
        return html
    }
 
    let randomIndex = 0
    let imgUrls = []
    // 先匹配到 img 标签，放到 match 里
    html = html.replace(/<img[^>]*>/gim, function(match) {
        randomIndex++
        match = match.replace(/src="[^"]+"/gim, function(match) {
            // 再匹配到 src 标签 '"'
            let src = match.slice(5, -1) + "?random=" + randomIndex // 取到 src 里面的 url
            imgUrls.push(src)
            return 'src="' + src + '"'
        }).replace(/src='[^']+'/gim, function(match) {
            // 再匹配到 src 标签 "'"
            let src = match.slice(5, -1) + "?random=" + randomIndex
            return "src='" + src + "'"
        })
        return match
    })
    return [html, imgUrls]
}
 
module.exports = {
    addRandomAndGetPreviewImageUrlsFromRichText: addRandomAndGetPreviewImageUrlsFromRichText,
}